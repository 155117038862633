import { Children, Fragment, cloneElement, useRef, useState } from "react";
import { createPortal } from "react-dom";

import useElements from "hooks/useElements";
import useForkRef from "hooks/useForkRef";
import useIntersectionObserver from "hooks/useIntersectionObserver";
import useUpdateEffect from "hooks/useUpdateEffect";
import setElementStyles from "utils/dom/setElementStyles";
import isNull from "utils/typeChecks/isNull";
import isString from "utils/typeChecks/isString";

import { INITIAL_STATE } from "./constants";
import FallbackElement from "./fallbackElement";
import useEvent from "hooks/useEvent";

/**
 * observationContainer -> should be passed container name which exist in elements provider
 */
const FlyingElement = ({ observationContainer = null, children }) => {
	const [fallBackOptions, setFallBackOptions] = useState({ ...INITIAL_STATE });

	const childe = Children.only(children);

	const originalElementRef = useRef(null);
	const fallBackElementRef = useRef(null);

	const { getElementFromStore } = useElements();

	const isIntersecting = useIntersectionObserver({
		targetRef: originalElementRef,
		root: isNull(observationContainer) ? observationContainer : getElementFromStore(observationContainer),
		threshold: 0.7
	});

	const forkRef = useForkRef(originalElementRef, childe?.ref);

	const handleStart = useEvent(
		({ target, startStyles = {}, transitionStyles = {}, offsetX = 0, offsetY = 0, side = "left" }) => {
			if (!isIntersecting) {
				return;
			}

			if (!isString(target)) {
				return console.error("target is required");
			}

			setFallBackOptions({
				isVisible: true,
				startStyles,
				transitionStyles,
				offsetX,
				offsetY,
				side,
				target
			});
		}
	);

	const handleStop = () => {
		setFallBackOptions({ ...INITIAL_STATE });
	};

	const handleTransitionEnd = (e) => {
		if (e.propertyName !== "transform") {
			return;
		}

		setFallBackOptions({ ...INITIAL_STATE });
	};

	useUpdateEffect(() => {
		if (!fallBackOptions.isVisible) {
			return;
		}

		const { target, offsetX, offsetY, startStyles, transitionStyles, side } = fallBackOptions;

		const targetElement = getElementFromStore(target);

		if (!targetElement) {
			return
		}

		const originalElementRect = originalElementRef.current.getBoundingClientRect();
		const targetElementRect = targetElement.getBoundingClientRect();

		setElementStyles(fallBackElementRef.current, {
			display: "block",
			top: `${originalElementRect.top}px`,
			left: `${originalElementRect.left}px`,
			width: `${originalElementRect.width}px`,
			height: `${originalElementRect.height}px`,
			...startStyles
		});

		const moveXAxis = targetElementRect[side] - originalElementRect.left + offsetX;
		const moveYAxis = targetElementRect.top - originalElementRect.top + offsetY;

		// Need for transition work
		setTimeout(() => {
			setElementStyles(fallBackElementRef.current, {
				transform: `translate(${moveXAxis}px, ${moveYAxis}px)`,
				opacity: "0",
				...transitionStyles
			});
		}, 10);
	}, [fallBackOptions]);

	const originalElement = cloneElement(childe, {
		ref: forkRef,
		onClick: (e) => childe.props.onClick(e, { startFlying: handleStart, stopFlying: handleStop })
	});

	return (
		<Fragment>
			{originalElement}
			{fallBackOptions.isVisible &&
				createPortal(
					<FallbackElement onTransitionEnd={handleTransitionEnd} ref={fallBackElementRef} />,
					document.body
				)}
		</Fragment>
	);
};

export default FlyingElement;
