import { BONUS_STATES } from "constants/bonus.constants";
import { SCHEDULED_GAME_CATEGORY } from "constants/game.constants";

const sliceName = "bonus";
const initialState = {
	useBonus: false,
	history: [],
	loading: false,
	filters: {
		status: BONUS_STATES.ALL,
		gameCategory: SCHEDULED_GAME_CATEGORY
	}
}

export { sliceName, initialState };