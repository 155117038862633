const sliceName = "statistics";
const initialState = {
	loading: false,
	football: {
		team1: null,
		team2: null,
		countries: null,
		team1Statistics: null,
		team2Statistics: null,
		h2h: []
	},
	penalty: {
		team1: null,
		team2: null,
		countries: null,
		team1Statistics: null,
		team2Statistics: null,
		h2h: {}
	},
	racing: {
		participants: [],
		currentParticipant: null,
		statistics: null,
		currentEvent: null
	},
	season: {
		id: null,
		standings: [],
		structure: {},
		loading: false,
		isUpdateRequestSended: false,
		showSeasonLiveModal: null
	},
	quickStatistics: {
		state: {
			visible: false
		},
		loading: false,
		statistics: [],
		teamsData: null
	},
	kenoStatistics: [],
	luckySixStatistics: {
		firstShowedOddCount: 0,
		firstShowedEvenCount: 0,
		lastShowedOddCount: 0,
		lastShowedEvenCount: 0,
		firstShowedOverCount: 0,
		firstShowedUnderCount: 0,
		lastShowedOverCount: 0,
		lastShowedUnderCount: 0,
		preNumberSumOddCount: 0,
		preNumberSumEvenCount: 0,
		preNumberSumOverCount: 0,
		preNumberSumUnderCount: 0,
		firstLastNumberSumOverCount: 0,
		firstLastNumberSumUnderCount: 0,
		colourStatistics: {
			red: { item1: 0, item2: 0 },
			orange: { item1: 0, item2: 0 },
			yellow: { item1: 0, item2: 0 },
			green: { item1: 0, item2: 0 },
			blue: { item1: 0, item2: 0 },
			violet: { item1: 0, item2: 0 },
			purple: { item1: 0, item2: 0 },
			black: { item1: 0, item2: 0 },
		},
		ballStatistics: []
	}
};

export { sliceName, initialState };
