import moment from "moment";

import {
	authenticateThunk,
	refreshTokenThunk,
	createSessionThunk,
	createStreamAccessThunk,
	streamConfigurationThunk,
	sessionDetailsThunk,
	updateSessionDetailsThunk,
	switchToRealModeThunk,
	saveSettingsThunk,
	setBonusThunk
} from "./thunks";

import {
	setPlayerAction,
	setSessionFailedAction,
	setBonusSeenAction,
	setBalanceAction,
	setLogoIdAction,
	setMaintenanceModeAction,
	setTranslationsLoadedAction,
	updateGameStateAction,
	updateCurrencyAction,
	setSessionAction
} from "./actions";

import SessionStorageUtils from "utils/sessionStorage";
import localStorage from "utils/localStorage";
import { getCurrentSettings } from "utils/settings";

import { BONUS_TYPE } from "constants/bonus.constants";

const fulfilledAuthenticationHandler = (state, { payload: player }) => {
	if (player) {
		SessionStorageUtils.set("player", player);
	} else {
		SessionStorageUtils.remove("player");
	}

	state.player = player;
};

const fulfilledSessionDetailsHandler = (state, { payload: { data: session, options } }) => {
	state.sessionFailed = false;
	state.isSessionUpdating = false;
	state.sessionLoaded = true;
	state.session = {
		...state.session,
		...session
	};

	if (session.bonuses) {
		state.session.bonuses = session.bonuses?.filter((bonus) =>
			(moment.utc(bonus.endDate) > moment.utc(Date.now())) && (
				bonus.bonusType === BONUS_TYPE.FREEBET ? bonus.roundCount > 0 : bonus.bonusType === BONUS_TYPE.FREEAMOUNT ? bonus.amount > 0 : false
			));
	}
}

export const addPlayerCases = (builder) => {
	builder.addCase(setPlayerAction, (state, { payload: { player } }) => fulfilledAuthenticationHandler(state, { payload: player }));

	builder.addCase(switchToRealModeThunk.pending, (state) => {
		state.switchingToRealMode = true;
	});

	builder.addCase(switchToRealModeThunk.fulfilled, (state, { payload: player }) => {
		state.switchingToRealMode = false;
		state.player = player;
		state.session.isDemo = false;
	});

	builder.addCase(switchToRealModeThunk.rejected, (state) => {
		state.switchingToRealMode = false;
	});

	builder.addCase(setBalanceAction, (state, { payload }) => {
		state.session.balance = payload.balance;
	});
}

export const addSessionCreationCases = (builder) => {
	builder.addCase(createSessionThunk.fulfilled, (state, { payload: data }) => {
		state.sessionId = data?.session?.sessionId;
	});

	builder.addCase(createSessionThunk.rejected, (state) => {
		state.sessionFailed = true;
	});

	builder.addCase(createStreamAccessThunk.fulfilled, (state, { payload: player, ...other }) => {
		// TODO: Need change after refactor,
		// TODO: without sessionFailed: true and sessionLoaded:true router
		// TODO: doesn't render Outlet
		state.sessionFailed = false;
		state.sessionLoaded = true;
		fulfilledAuthenticationHandler(state, { payload: player })
	});

	builder.addCase(createStreamAccessThunk.rejected, (state) => {
		state.sessionFailed = true;
		state.sessionLoaded = true;
	});
};

export const addSessionDetailsCases = (builder) => {
	builder.addCase(updateGameStateAction, (state, { payload: { game } }) => {
		state.session.games = state.session.games.map((sessionGame) => {
			if (sessionGame.id === game.id) {
				return {
					...sessionGame,
					state: game.gameState
				}
			}
			return sessionGame
		});
	});

	builder.addCase(setSessionFailedAction, (state, { payload: { sessionFailed } }) => {
		state.sessionFailed = sessionFailed;
	});

	builder.addCase(sessionDetailsThunk.fulfilled, fulfilledSessionDetailsHandler);

	builder.addCase(sessionDetailsThunk.rejected, (state) => {
		state.sessionFailed = true;
		state.sessionLoaded = true;
	});

	builder.addCase(updateSessionDetailsThunk.pending, (state) => {
		state.isSessionUpdating = true;
	});

	builder.addCase(updateSessionDetailsThunk.fulfilled, fulfilledSessionDetailsHandler);

	builder.addCase(updateSessionDetailsThunk.rejected, (state) => {
		state.isSessionUpdating = false;
	});

	builder.addCase(updateCurrencyAction, (state, { payload: { updatedCurrency } }) => {
		state.session.currency = {
			...state.session.currency,
			...updatedCurrency,
		}
	});

	builder.addCase(setSessionAction, (state, { payload: { session } }) => {
		state.session = {
			...state.session,
			...session
		}
	});
};

export const addSessionLogoCases = (builder) => {
	builder.addCase(setLogoIdAction, (state, { payload }) => {
		state.session.logoId = payload.logoId;
	});
}

export const addStreamCases = (builder) => {
	builder.addCase(streamConfigurationThunk.fulfilled, (state, { payload }) => {
		state.session.games = state.session.games.map((g) => (g.id === payload.id ? { ...g, streamConfiguration: payload.configuration } : g));
	});
}

export const addAuthenticationCases = (builder) => {
	builder.addCase(authenticateThunk.fulfilled, fulfilledAuthenticationHandler);

	builder.addCase(authenticateThunk.rejected, (state) => {
		state.sessionFailed = true;
	});

	builder.addCase(setMaintenanceModeAction, (state) => {
		state.session.maintenanceMode = true;
	});

	builder.addCase(setTranslationsLoadedAction, (state, { payload: { translationsLoaded } }) => {
		state.translationsLoaded = translationsLoaded;
	})
};

export const addSaveSettingsCases = (builder) => {
	builder.addCase(saveSettingsThunk.fulfilled, (state, { payload: settings }) => {
		state.session.settings = settings;
		localStorage.set(`vs__settings__${state.session.projectId}`, settings);
	});

	builder.addCase(saveSettingsThunk.rejected, (state, { error, meta: { arg } }) => {
		if (error.name === "AxiosError") {
			return;
		}

		state.session.settings = arg;
		localStorage.set(`vs__settings__${state.session.projectId}`, arg);
	});
};

export const addBonusCases = (builder) => {
	builder.addCase(setBonusThunk.fulfilled, (state, { payload: bonuses }) => {
		state.session.bonuses = bonuses;
	});

	builder.addCase(setBonusSeenAction, (state) => {
		state.session.bonuses = state.session.bonuses.map((b) => ({ ...b, isNewBonus: false }));
	});
}